  mdui-text-field {
    padding-bottom: 24px;
  }
  
  mdui-list-item{
    background-color: #FDF7FF;
    padding: 12px;
  }
  
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    width: 100%;
    overflow: ;
  }
  
  .flex-container > .login.banner {
    flex-grow: 10;
    background:#DED8E1;
  }

  .form-container{
    width: 360px;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  mdui-list{
    margin-left: 20px;
    margin-right: 20px;
  }

  .slider-container{
    width: 600px;
  }
  
  .center{
    max-inline-size: 100%;
    block-size: auto;
    margin-top: 32px;
  }
  
  
  .flex-container > .login.form {
    height: auto;   
    max-width: 460px;
    background-color: white;
    /* M3/Elevation Light/1 */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 1024px) {
    .banner{
      display: none;
    }
  
    .flex-container {
      flex-direction: column; 
      align-items: center;
    }
  
  }
  
  @media screen and (max-width: 360px) {
    mdui-text-field {
      max-width: 100%;
    }

    .form-container{
        width: auto;
    }
  }
  
 /* @media screen and (max-height: 600px) {
    .flex-container > .login.form {
      height: 800px;
    }
  }*/
  