
/* Modify the color value of primary */
:root {
  --mdui-color-primary-light: black;
  --mdui-color-primary-dark: 208, 188, 255;
}

body{
  margin: 0;
}

/* Set the background color of the foo element to primary */
mdui-navigation-rail {
  background-color: --mdui-color-primary;
}

.text-align-center{
  text-align: center;
}

* {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.kodchasan-extralight {
  font-family: "Kodchasan", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.kodchasan-light {
  font-family: "Kodchasan", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.kodchasan-regular {
  font-family: "Kodchasan", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kodchasan-medium {
  font-family: "Kodchasan", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kodchasan-semibold {
  font-family: "Kodchasan", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kodchasan-bold {
  font-family: "Kodchasan", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kodchasan-extralight-italic {
  font-family: "Kodchasan", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.kodchasan-light-italic {
  font-family: "Kodchasan", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.kodchasan-regular-italic {
  font-family: "Kodchasan", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.kodchasan-medium-italic {
  font-family: "Kodchasan", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.kodchasan-semibold-italic {
  font-family: "Kodchasan", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.kodchasan-bold-italic {
  font-family: "Kodchasan", sans-serif;
  font-weight: 700;
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Kodchasan", sans-serif;
  font-weight: 400;
  font-style: bold;
  margin-top: 0 !important;
}

/* Set the background color of the bar element to primary with 0.8 opacity */
.bar {
  background-color: rgba(var(--mdui-color-primary), 0.8);
}

.mgt32 {
  margin-top: 32px !important;
}

.mgt64 {
  margin-top: 64px;
}

.mgb32{
  margin-bottom: 32px;
}

.text-primary{
  font-weight: 500;
  color: #6833EA ;
}
