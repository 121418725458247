/* src/carousel.css */
body, html {
  height: 100%;
  margin: 0;
}

.carrousel-area{
  display: flex;
  flex-direction: column; /* Asegura que los elementos hijos estén en columna */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  width: auto;
  height: 100vh;
}

.main-title {
  font-weight: 500;
  font-size: 40px; /* Tamaño grande para el título */
  margin: 20px; /* Separación entre el título y el carrusel */
  color: #6833EA; /* Color del texto */
  /*background-color: #F2E57F;*/
  padding: 10px;
  text-align: center;
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 90%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.carousel-image-container {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-slide {
  min-width: 100%;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  /*object-fit: contain;*/
  border-radius: 30px;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  z-index: 1;
}

.carousel-caption h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.carousel-caption p {
  margin: 5px 0 0;
  font-size: 16px;
}

.progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 2;
}

.progress-bar {
  height: 100%;
  background-color: #F2E57F;
  transition: width 0.1s linear;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #6833EA;
  opacity: 0.8;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 2;
  border-radius: 10px;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.pause-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6833EA;
  opacity: 0.8;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  z-index: 2;
  border-radius: 10px;
}

/* Skeleton Loader */
.preloader {
  display: flex;
  width: 100%;  
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  z-index: 10;
}

/* Animación del skeleton loader */
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
} 

@media (max-width: 1400px) {
  .carousel {
    max-width: 90%;
   /* border-radius: 0px;*/
  }

  .carousel-image {
    height: auto;
    /*border-radius: 0px;*/
  }

  .carousel-caption h2 {
    font-size: 20px;
  }

  .carousel-caption p {
    font-size: 14px;
  }

  .carousel-button {
    padding: 8px;
    font-size: 18px;
  }

  .pause-button {
    left: 93%;
    font-size: 14px;
    padding: 6px 12px;
  }
}
